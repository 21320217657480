$header_color: #e8dce5;
$text_color: #000000;
$button_color: #ff5492;
$button_border_color: #ff5492;
$button_text_color: #491c1c;
$font_inria: 'Inria Serif';
$font_istok: 'Istok Web';
$font_inter: 'Inter';
$font_arial: 'Arial';
$desktop: 960px;
$screen_medium: 440px;
$screen_large: 1400px;
$red: #ff3838;
$grey: #d9d9d9;
//$text_color: #491c1c;

body {
  color: $text_color;
  font-size: 18px;
  font-family: $font_istok;
}

.button {
  font-family: $font_istok;
  text-decoration: none;
  text-align: center;
  background-color: $button_color;
  border: 1px solid $button_border_color;
  color: white;
  border-radius: 25px;
  cursor: pointer;

  padding: 10px;
  font-size: 18px;
  width: 150px;

  @media screen and (min-width: $desktop) {
    padding: 12px;
    font-size: 20px;
    width: 160px;
  }

  &.phone {
    @media screen and (min-width: $desktop) {
      cursor: default;
    }
  }
}

.icon {
  @media screen and (min-width: $desktop) {
    height: 100px;
  }
  height: 50px;
}

.mail-icon {
  margin-top: 12px;
  height: 50px;
  width: 100px;

  @media screen and (min-width: $screen_large) {
    height: 75px;
    width: 150px;
  }
}

ul {
  padding-left: 20px;
  @media screen and (min-width: $desktop) {
    padding-left: 6px;
  }

  li {
    margin-bottom: 12px;
  }
}

img {
  &.img-desktop {
    display: none;
    @media screen and (min-width: $desktop) {
      display: block;
    }
  }

  &.img-mobile {
    display: block;
    @media screen and (min-width: $desktop) {
      display: none;
    }
  }
}

.form-input {
  margin-top: 10px;
  display: flex;

  label {
    font-family: $font_inter;
    margin-top: 14px;

    width: 40%;
    @media screen and (min-width: $desktop) {
      width: 40%;
    }
  }

  input {
    font-family: $font_arial;
    height: 24px;
    width: 60%;

    &.error {
      border-color: $red;

      &:focus {
        border-color: $red;
      }
    }
  }
}

.form-text {
  margin-top: 22px;

  label {
    display: block;
    width: 100%;
  }

  textarea {
    font-family: $font_arial;
    padding: 10px;
    width: 93%;
    height: 200px;

    @media screen and (min-width: $desktop) {
      height: 300px;
      width: 100%;
    }

    &.error {
      border-color: $red;

      &:focus {
        border-color: $red;
      }
    }
  }
}

h2, h3 {
  font-family: $font_istok;
}

.message {
  margin: 10px 0;
  padding: 5px;

  &.notice {
    border: 1px solid #3d9a2b;
    background-color: #d4ecd0;
  }

  &.error {
    border: 1px solid $red;
    background-color: #fae0e0;
  }
}

header {
  display: flex;
  text-align: right;
  padding-right: 20px;
  font-family: $font_inria;
  background-color: $header_color;
  color: $text_color;

  div:first-child {
    width: 90%;
  }

  h1 {
    font-weight: normal;
    padding: 12px;
    margin: 0;

    font-size: 24px;

    @media screen and (min-width: $screen_medium) {
      font-size: 24px;
    }

    @media screen and (min-width: $desktop) {
      font-size: 36px;
      padding: 18px;
    }
  }

  img {
    width: 40px;
    height: 40px;
    padding: 7px;
    padding-left: 0;


    @media screen and (min-width: $desktop) {
      width: 60px;
      height: 60px;
      padding: 9px;
    }
  }
}

.hero {
  font-family: $font_inria;

  background-image: url("./assets/img/pink-cloud.jpeg");
  background-repeat: no-repeat;
  background-size: 120%;

  height: 250px;
  background-position: 0px;
  background-position-y: 0px;

  @media screen and (min-width: $desktop) {
    background-size: cover;
    position: relative;
    height: 790px;
    background-position-y: -140px;
  }

  div {
    color: white;
    font-size: 16px;
    width: 50%;

    padding-top: 25px;
    padding-left: 20px;

    @media screen and (min-width: $screen_medium) {
      font-size: 18px;
    }

    @media screen and (min-width: $desktop) {
      position: absolute;
      width: 500px;
      top: 150px;
      left: 150px;
      height: 458px;
      padding: 0;
      font-size: 34px;
    }

    span {
      line-height: 1.3;
    }

    .span-large {
      font-size: 18px;

      @media screen and (min-width: $desktop) {
        font-size: 46px;
      }
    }
  }
}

.service-buttons {
  display: none;

  @media screen and (min-width: $desktop) {
    display: flex;
    justify-content: space-around;
    padding: 10px 50px;
    margin-bottom: 40px;
  }

  button {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.services {
  @media screen and (min-width: $desktop) {
    display: flex;
    justify-content: space-around;
    padding: 10px 50px;
    margin-top: 40px;
  }

  .service-container {
    flex-basis: 0;
    flex-grow: 1;

    .service {
      &.service-desktop {
        display: none;
        @media screen and (min-width: $desktop) {
          display: block;
        }
      }

      &.service-mobile {
        display: block;
        @media screen and (min-width: $desktop) {
          display: none;
        }
      }

      width: 65%;
      margin: 10px auto;
      padding: 10px;
      border-radius: 25px;
      text-align: center;

      @media screen and (min-width: $desktop) {
        width: 100%;
      }

      .button {
        font-size: 22px;
      }

      > div {
        height: 80%;
        display: flex;
        justify-content: center;
        @media screen and (min-width: $desktop) {
          display: block;
        }

        > div {
          padding-top: 4px;
          width: 20%;
          margin-right: 10px;
        }

        p {
          width: 80%;
          margin: 0 auto;
        }
      }

      .button {
        vertical-align: bottom;
      }

      img {
        vertical-align: bottom;
        margin-right: 0;

        &.iron-icon {
          margin-left: -20px;
        }

        @media screen and (min-width: $desktop) {

          vertical-align: unset;
          margin: 0;
        }
      }
    }
  }


}

.cta {
  background-image: url("./assets/img/iron-steam-dark.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0;

  height: 300px;
  color: white;

  @media screen and (min-width: $screen_medium) {
    background-size: cover;
  }

  @media screen and (min-width: $desktop) {
    background-position-y: -87px;
    position: relative;
    margin: 0 auto;
    height: 828px;
    margin-bottom: 20px;
  }

  .action {
    padding-top: 32px;
    padding-left: 10px;
    font-size: 14px;

    @media screen and (min-width: $desktop) {
      padding: 0;

      text-align: center;
      font-size: 25px;
      position: absolute;
      top: 60px;
      left: 150px;
      height: 200px;
    }

    h2 {
      font-family: $font_inria;
      font-weight: normal;
      margin-bottom: 20px;
    }

    .button {
      font-size: 13px;
      font-family: $font_inter;
      padding: 8px;
      width: 100px;
      margin-left: 80px;

      @media screen and (min-width: $desktop) {
        font-size: 20px;
        padding: 12px;
        width: 160px;
        margin-left: 0px;
      }
    }
  }
}

.cta2 {
  font-family: $font_inria;
  line-height: 0;
  width: 100%;
  position: relative;

  @media screen and (min-width: $desktop) {
    margin: 0 auto;
    width: 60%;
  }

  img {
    width: 100%;
  }

  .action {
    line-height: normal;
    text-align: right;
    color: $text_color;
    position: absolute;
    top: 5px;
    right: 10px;

    &.whatsapp-action {

      a {
        margin-right: 35px;

        .icon {
          width: 40px;
          height: 40px;

          @media screen and (min-width: $desktop) {
            width: 50px;
            height: 50px;
          }
        }
      }
    }

    @media screen and (min-width: $screen_medium) {
      top: 30px;
    }

    @media screen and (min-width: $desktop) {
      right: 50px;
      height: 300px;
      width: 600px;
    }

    .button {
      font-size: 14px;
      font-family: $font_inter;
      padding: 8px;
      width: 100px;
      margin-right: 33px;
      @media screen and (min-width: $desktop) {
        font-size: 20px;
        padding: 12px;
        width: 130px;
      }
    }

    > div {
      text-align: right;

      * {
        display: inline-block;
      }

      h2 {
        font-family: $font_inria;
        font-weight: normal;
        font-size: 18px;
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: 8px;
        display: block;

        &:nth-child(2) {
          font-weight: bold;
          font-size: 20px;
          display: inline-block;
          vertical-align: top;
          margin-right: 10px;

          @media screen and (min-width: $screen_medium) {
            font-size: 24px;
          }

          @media screen and (min-width: $desktop) {
            width: 60%;
            font-size: 32px;
          }
        }

        @media screen and (min-width: $screen_medium) {
          font-size: 24px;
        }

        @media screen and (min-width: $desktop) {
          font-size: 32px;
          width: 600px;
          margin-right: 20px;
        }
      }
    }

    .icon {
      width: 35px;
      height: 35px;

      @media screen and (min-width: $desktop) {
        width: 75px;
        height: 75px;
      }
    }
  }
}

.about-us {

  .about-service {

    @media screen and (min-width: $desktop) {
      width: 80%;
      margin: 0 auto;
      display: flex;
    }

    .about-us-info {

      width: 80%;
      margin: 0 auto;

      @media screen and (min-width: $desktop) {
        width: 50%;
        padding: 30px;

        &:nth-child(2) {
          border-left: 1px solid grey;
        }

        h2 {
          margin-top: 0;
        }
      }

      .about-us-info-images {
        overflow: hidden;
      }

      img {
        width: 50%;
        vertical-align: top;
      }

      ul {
        margin: 0;
        font-family: $font_inter;
      }
    }
  }

  p {
    font-family: $font_inter;
    text-align: left;
  }

  img {
    width: 100%;
    @media screen and (min-width: $desktop) {
      height: 200px;
      width: auto;
    }
  }

  .about-us-images {
    margin-top: 20px;
    text-align: center;
    line-height: 0;

    @media screen and (min-width: $desktop) {
      width: 80%;
      margin: 0 auto;
    }

    img {
      width: 100%;
      @media screen and (min-width: $desktop) {
        height: auto;
      }
    }

    div {
      display: flex;

      img {
        width: 50%;
      }
    }
  }
}

.contact {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;

  display: block;
  @media screen and (min-width: $desktop) {
    display: flex;
  }

  > img {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (min-width: $desktop) {
      margin-top: 96px;
      height: 500px;
      width: auto;
    }
  }

  .contact-form {
    margin: 0;
    width: 100%;

    @media screen and (min-width: $desktop) {
      margin-left: 50px;
      width: 80%;
    }

    @media screen and (min-width: $screen_large) {
      width: 600px;
      margin-left: 200px;
    }

    .address-block {
      display: flex;
    }

    h3 {
      font-size: 28px;
    }

    p {
      font-size: 24px;
      margin-top: 0;
    }

    .google-maps-icon {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }

    .button {
      width: 115px;
      @media screen and (min-width: $desktop) {
        width: 135px;
      }
    }

    .contact-header {
      display: flex;
      margin-top: 40px;

      div {
        width: 40%;

        img {
          display: block;

          margin: 0;
          margin-top: -10px;

          @media screen and (min-width: $desktop) {
            margin-top: 0px;
          }

          @media screen and (min-width: $screen_large) {
            margin-top: -20px;
          }
        }
      }

      h2 {
        font-family: $font_inter;
        width: 60%;
        font-size: 20px;
        @media screen and (min-width: $desktop) {
          font-size: 27px;
        }
      }
    }

    .required-desc {
      font-size: 13px;
      color: grey;
      margin-bottom: 10px;
    }
  }
}

.prices {
  margin: 0 auto;
  margin-bottom: 50px;
  text-align: left;
  width: 80%;

  @media screen and (min-width: $desktop) {
    width: 30%;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  .price-table {

    .price-row {
      font-family: $font_inter;
      display: flex;
      justify-content: space-around;
      margin-bottom: 5px;
      border-bottom: 1px solid grey;
      padding: 5px 0;

      &:first-child {
        border-top: 1px solid grey;
      }

      .item-name {
        text-align: left;
        width: 80%;
      }

      .item-euro {
        width: 10%;
        text-align: right;
        margin-right: 2px;
      }

      .item-price {
        text-align: right;
        padding-right: 15px;
        width: 10%;
        min-width: 50px;
      }
    }
  }
}

footer {
  font-family: $font_inria;
  text-align: center;

  font-size: 16px;
  @media screen and (min-width: $desktop) {
    font-size: 18px;
  }

  .footer-container {
    background-color: $header_color;
    padding: 20px 20px;

    @media screen and (min-width: $desktop) {
      padding: 20px 0;
    }

    span {
      display: block;
      margin-bottom: 10px;
      @media screen and (min-width: $desktop) {
        display: inline;
      }
    }

    span:nth-child(2) {
      @media screen and (min-width: $desktop) {
        margin-left: 100px;
      }
    }

    .google-maps-icon {
      width: 25px;
      height: 25px;
      margin-left: 10px;
      vertical-align: bottom;
    }
  }
}

.under-construction {
  background-color: azure;
  padding: 25px;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  .span {
    display: inline-block;
    margin-left: 25px;
  }

  .icon {
    width: 5opx;
    height: 50px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 25px;
  }
}

.temp-close {
  text-align: center;
  padding: 25px;
  border-bottom: 10px solid $grey;
  background-color: white;
  z-index: 10000;
  height: 110px;

  & + header {
    margin-top: 170px;
  }

  @media screen and (min-width: $desktop) {
    height: 30px;

    & + header {
      margin-top: 90px;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  h3 {
    margin: 0;
  }
}

.password-screen {
  padding: 20px;
  font-size: 3em;

  span {
    vertical-align: top;
  }

  input {
    width: 300px;
    height: 60px;
    font-size: 1.2em;
    margin-left: 10px;
  }
}

.admin-tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid black;
  font-size: 3em;
}

.admin-tab {
  width: 50%;
  text-align: center;
  text-decoration: none;
  color: black;
  padding: 10px 0;


  &:first-child {
    border-right: 1px solid black;
  }

  &.active {
    background-color: #dbdbff;
  }
}

.calculator {
  margin: 0 auto;
  width: 90%;
  font-size: 2em;

  .calculator-total {
    font-size: 2em;
  }

  .action-buttons, .action-buttons-bottom {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;

    div {
      margin-right: 50px;
    }
  }

  .action-buttons-bottom {
    justify-content: space-between;

    .input-customer-name {
      padding-top: 5px;

      input {
        height: 30px;
        width: 160px;
        font-size: 1.1em;
      }
    }
  }

  .save-error {
    border: 1px solid red;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    background-color: #fdd7dd;
  }

  .toggle-selected, .toggle-save, .save-button {
    padding: 5px;
    display: inline-block;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #dbdbff;
    cursor: pointer;
  }

  .toggle-save, .save-button {
    background-color: #e6fbe6;

    &.cancel {
      background-color: #fdd7dd;
    }
  }
}

.calculator-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;

  &.active {
    background-color: #eafbea;
  }

  span {
    width: 50px;
    text-align: right;
    margin-right: 5px;
  }

  .calc-button {
    width: 40px;
    height: 40px;
    padding: 3px 10px;
    font-size: 2.2em;
    border: 1px solid grey;
    border-radius: 5px;
    line-height: 0.6;
    background-color: #fdd7dd;
  }

  .calc-button-plus {
    height: 60px;
    width: 40px;
    line-height: 1.4;
    font-size: 1.4em;
    padding-left: 20px;
    background-color: #e6fbe6;
  }

  .item-name {
    width: 300px;
    text-align: left;
    font-size: 1.1em;
  }

  .item-amount {
    font-size: 1.2em;
    width: 50px;
    height: 50px;
    text-align: right;
  }

  .read-item-amount {
    font-size: 1.2em;
    padding-right: 10px;
  }

  .item-total {
    font-size: 1.1em;
    width: 100px;
  }
}

.calculator-total-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
  padding-left: 10px;
  padding-right: 14px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.invoice {
  margin: 0 auto;
  width: 90%;
  font-size: 2em;

  .back-link {
    margin: 20px 10px;
    text-decoration: underline;
  }

  .invoice-data {
    margin: 20px 10px;

    .invoice-data-item {
      display: flex;
      justify-content: space-between;

      &.invoice-customer {
        font-size: 1.5em;
      }
    }
  }

  .calculator-item {
    background-color: #f1e5ee;
  }

  .calculator-total-bottom {
    background-color: #e7e7f9;
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
  }
}

.invoices {
  margin: 0 auto;
  width: 90%;
  font-size: 2em;

  .month-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;

    button {
      height: 80px;
      font-size: 2em;
      background-color: #dbdbff;
      border: 1px solid black;
      border-radius: 5px;
    }

    h2 {
      margin: 3px 30px;
    }
  }

  .invoices-empty {
    margin: 20px 0;
    text-align: center;
  }

  .invoice-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 10px;
    text-decoration: none;
    color: black;

    &:last-child {
      border-bottom: none
    }
  }

  .invoice-total {
    font-weight: bold;
    border-top: 2px solid black;
  }
}